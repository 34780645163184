const userRoles = {
    ADMIN: 'admin',
    NORMAL: 'normal',
}

const userStatuses = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
}

module.exports = {
    userRoles,
    userStatuses,
}
