import React from 'react';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import UserEditOptions from '../user-edit-options';
import SwitchButton from '../enable-switch';

export default function data({
	popupActions,
	userListActions,
}) {
	const User = ({
		name,
		email,
		style,
		onClick
	}) => {
		return (
			<MDBox display="flex" alignItems="center" lineHeight={1} onClick={onClick}>
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium" sx={style}>
						{name}
					</MDTypography>
					<MDTypography variant="caption">{email}</MDTypography>
				</MDBox>
			</MDBox>
		)
	};

	const createRows = (data) => {
		return [
			...transformDataToRows(data),
			createUserRow(),
		]
	}

	const transformDataToRows = (data) => {
		return data.map(user => ({
			name: <User
				name={user.name}
				email={user.email}
			/>,
			status: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					<SwitchButton item={user}/>
				</MDTypography>
			),
			mainInfo: (
				<MDTypography
					component="a" variant="caption" color="text" fontWeight="medium"
					onClick={() => popupActions.open(user)}>
					Edit
				</MDTypography>
			),
			editActions: (
				<MDTypography
					component="a" variant="caption" color="text" fontWeight="medium">
					<UserEditOptions
						user={user}
						userListActions={userListActions}
					/>
				</MDTypography>
			),
		}))
	}

	const createUserRow = () => ({
		name: <User
			style={{
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: 'lightgray',
				},
			}}
			name={'Create new user...'}
			email={''}
			onClick={userListActions.addDraft}
		/>,
	})

	return {
		columns: [
			{ Header: "User", accessor: "name", width: "45%", align: "left" },
			{ Header: "Status", accessor: "status", align: "left" },
			{ Header: "User data", accessor: "mainInfo", align: "left" },
			{ Header: "Actions", accessor: "editActions", align: "left" },
		],
		createRows,
	};
}
