const countryCodes = require('country-codes-list')

export const providerType = {
    investmentProvider: 'investmentProvider',
    financialEducationProvider: 'financialEducationProvider',
}

export const categories = {
    stocks: 'Stocks',
    tradingAccounts: 'Trading Accounts',
    fundsETFs: 'Funds/ETFs',
    managedAccounts: 'Managed Accounts', // Finding advisors
    financialEducators: 'Financial Educators',
    crypto: 'Crypto',
}
export const categoriesEnum = Object.values(categories);

export const providerStatus = {
    enabled: 'enabled',
    disabled: 'disabled',
}

export const investmentProviderProperties = {
    name: 'Provider name',
    description: 'Provider description',
    rating: 'Provider rating',
    link: 'Provider link',
    categories: 'Categories',
    labels: 'Labels',
    status: 'Status',
    image: 'Image',

    minDeposit: 'Provider minimal deposit',
    investmentManagementType: 'Investment management type',
    supportedCountriesOfResidence: 'Supported countries of residence',
    investmentType: 'Investment type',
    supportedStocks: 'Supported stocks',
    supportedEtfs: 'Supported ETFs',
}

export const financialEducationProvidersProperties = {
    name: 'Provider name',
    description: 'Provider description',
    rating: 'Provider rating',
    link: 'Provider link',
    categories: 'categories',
    labels: 'labels',
    status: 'status',
    image: 'image',

    educationCourseTypes: 'Education course types',
    supportedFormats: 'Supported formats',
    levelsOfEducation: 'Levels of education',
}

export const investmentManagementTypes = {
    self: 'Self',
    delegated: 'Delegated',
}
export const investmentTypes = {
    jointAccount: 'Via a joint account',
    ownName: 'In your own name',
    privateLimitedCompany: 'Via a private limited company',
    someoneElse: 'On behalf of someone else',
}
export const educationCourseTypes = {
    trading: 'Trading',
    investing: 'Investing',
    crypto: 'Crypto',
}
export const supportedFormats = {
    preRecorded: 'Pre-recorded',
    live: 'Live',
    oneOnOne: 'One on one',
}
export const levelsOfEducation = {
    introductory: 'Introductory',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
}

export const countryCodesList = countryCodes.customList('countryCode', '{countryNameEn}')
