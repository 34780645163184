import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function SelectField({
	name,
	value,
	onChange,
	selectOptions,
}) {
	return (
		<FormControl sx={{ marginTop: 2 }} fullWidth>
			<InputLabel id="demo-simple-select-label">{name}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				label={name}

				sx={{ height: '40px' }}
				name={name}
				value={value}
				onChange={onChange}
				fullWidth
			>
				{Object.keys(selectOptions).map((key, index) => (
					<MenuItem key={index} value={selectOptions[key]}>
						{selectOptions[key]}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default SelectField;
