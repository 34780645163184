import React from 'react';
import { Switch } from '@mui/material';

import { providerStatus } from '../constants';

const SwitchButton = ({
	provider,
	providerListActions,
}) => {
	const enabled = provider.status === providerStatus.enabled;
	const handleChange = async () => {
		provider.status = nextStatus(enabled);
		await providerListActions.update(provider);
	};

	const nextStatus = () => {
		return provider.status === providerStatus.enabled
			? providerStatus.disabled
			: providerStatus.enabled;
	}

	return (
		<Switch
			checked={enabled}
			onChange={handleChange}
			color="primary"
		/>
	);
};

export default SwitchButton;
