import { TextField } from '@mui/material';
import React from 'react';
import { camelCaseToTitleCase } from '../../utils/case.helper';

function ProviderTextField ({
	name,
	value = '',
	onChange,
}) {
	const defaultMaxLength = 5000;
	const label = camelCaseToTitleCase(name);

	const limitTextLength = (event) => {
		const { value } = event.target;

		if (value.length <= defaultMaxLength) {
			onChange(event);
		} else {
			onChange({
				...event,
				target: {
					...event.target,
					value: value.slice(0, defaultMaxLength)
				}
			});
		}
	}

	const placeholder = `Max ${defaultMaxLength} symbols.`

	return (
		<TextField
			sx={{ marginTop: 2 }}
			id={name}
			label={label}
			name={name}
			value={value}
			onChange={limitTextLength}
			placeholder={placeholder}
			fullWidth
		/>
	)
}

export default ProviderTextField;