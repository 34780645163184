import routes from "routes";
import { useEffect } from "react";
import { useMaterialUIController } from "context";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import devto from "assets/images/small-logos/devto.svg";

import { request } from 'services/request';

export default function App() {
	const [controller] = useMaterialUIController();
	const {
		direction,
		layout,
		sidenavColor,
		transparentSidenav,
		darkMode,
	} = controller;
	const { pathname } = useLocation();
	const navigate = useNavigate();

	// Setting the dir attribute for the body element
	useEffect(async () => {
		Promise.resolve()
			.then(() => document.body.setAttribute("dir", direction))
			.then(authRequest)
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const authRequest = async () => {
		try {
			await request.get({ path: 'user/me' });
		} catch (error) {
			if (error.response && error.response.status === 401) {
				navigate('/authentication/sign-in');
			} else {
				console.error(error);
			}
		}
	}
	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key}/>;
			}

			return null;
		});

	return <ThemeProvider theme={darkMode ? themeDark : theme}>
		<CssBaseline/>
		{layout === "dashboard" && (
			<>
				<Sidenav
					color={sidenavColor}
					brand={(transparentSidenav && !darkMode) || devto}
					brandName="Pear backoffice"
					routes={routes}
				/>
			</>
		)}
		<Routes>
			{getRoutes(routes)}
			<Route path="*" element={<Navigate to="/investment-providers"/>}/>
			{/*<Route path="*" element={<Navigate to="/investment-providers" />} />*/}
		</Routes>
	</ThemeProvider>
}
