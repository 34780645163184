export const oldDefaultInvestmentLabels = {
	numberOfStocks: 'Number of Stocks',
	numberOfEtfs: 'Number of ETFs',
	marketsExchangesOffered: 'Markets/Exchanges offered',
	fractionalSharesAvailable: '\'Fractional Shares\' Available',
	commissionFreeAvailable: '\'Commission Free\' Available',
	depositMethodsAccepted: 'Deposit methods accepted',
	withdrawalFees: 'Withdrawal Fees',
	mobileApp: 'Mobile app',
	webApp: 'Web app',
	desktopApp: 'Desktop app',
	interestOnCashBalances: 'Interest on cash balances',
	languageSupport: 'Language support',
	supportAvailability: 'Support availability',
	CFDTradingAvailable: 'CFD trading available',
	options: 'Options',
	cryptoAssets: 'Crypto assets',
	bankingAndDebitCard: 'Banking and Debit Card',
	foreignExchange: 'Foreign Exchange',
}

export const defaultInvestmentLabels = {
	reliability: {
		name: 'Reliability',
		value: '',
		description: 'Platform connectivity. Does it crash often? Problems with logging in or staying logged in?',
		rating: 1,
	},
	instrumentOffering: {
		name: 'Instrument offering',
		value: '',
		description: 'Does it have all instruments that I would like to trade/invest in?',
		rating: 1,
	},
	execution: {
		name: 'Execution',
		value: '',
		description: 'Have I had any problems opening/closing, buying/selling my positions when I wanted to?',
		rating: 1,
	},
	customerSupport: {
		name: 'Customer support',
		value: '',
		description: 'Do I get my queries resolved in time and in a friendly manner? Do I really get support?',
		rating: 1,
	},
	easyRegistration: {
		name: 'Easy registration',
		value: '',
		description: 'The onboarding questionnaire and documentation required was very straightforward.',
		rating: 1,
	},
	fundingCards: {
		name: 'Funding (cards)',
		value: '',
		description: 'Are my (card) deposits and withdrawals processed in good time and without issues.',
		rating: 1,
	},
	fundingBank: {
		name: 'Funding (bank)',
		value: '',
		description: 'Are my (bank) deposits and withdrawals processed in good time and without issues.',
		rating: 1,
	},
	fundingOther: {
		name: 'Funding (other)',
		value: '',
		description: 'Are my (other) deposits and withdrawals processed in good time and without issues.',
		rating: 1,
	},
	communications: {
		name: 'Communications',
		value: '',
		description: 'Do their marketing communications add value to me or are they too much spam and FOMO?',
		rating: 1,
	},
	platformProprietaryQuality: {
		name: 'Platform (proprietary) quality',
		value: '',
		description: 'Is the platform fast and easy to use?',
		rating: 1,
	},
	overallUserExperience: {
		name: 'Overall user experience',
		value: '',
		description: 'I feel that this provider is great and I don\'t see why I should change it.',
		rating: 1,
	},
}

export const defaultInvestmentLabelsStub = Object.values(defaultInvestmentLabels);
