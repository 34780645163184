import React from 'react';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ArticleEditOptions from '../article-edit-options';
import SwitchButton from '../enable-switch';

export default function data({
	popupActions,
	listActions,
}) {
	const ArticleRow = ({
		name,
		description,
		style,
		onClick
	}) => {
		return (
			<MDBox display="flex" alignItems="center" lineHeight={1} onClick={onClick}>
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium" sx={style}>
						{name}
					</MDTypography>
					<MDTypography variant="caption">{description}</MDTypography>
				</MDBox>
			</MDBox>
		)
	};

	const createRows = (data) => {
		return [
			...transformDataToRows(data),
			createRow(),
		]
	}

	const transformDataToRows = (data) => {
		return data.map(item => ({
			name: <ArticleRow
				name={item.name}
				description={item.description}
			/>,
			status: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					<SwitchButton
						item={item}
						listActions={listActions}
					/>
				</MDTypography>
			),
			mainInfo: (
				<MDTypography
					component="a" variant="caption" color="text" fontWeight="medium"
					onClick={() => popupActions.open(item)}>
					Edit
				</MDTypography>
			),
			editActions: (
				<MDTypography
					component="a" variant="caption" color="text" fontWeight="medium">
					<ArticleEditOptions
						item={item}
						listActions={listActions}
					/>
				</MDTypography>
			),
		}))
	}

	const createRow = () => ({
		name: <ArticleRow
			style={{
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: 'lightgray',
				},
			}}
			name={'Create new article...'}
			description={''}
			onClick={listActions.addDraft}
		/>,
	})

	return {
		columns: [
			{ Header: "Article", accessor: "name", width: "45%", align: "left" },
			{ Header: "Status", accessor: "status", align: "left" },
			{ Header: "data", accessor: "mainInfo", align: "left" },
			{ Header: "Actions", accessor: "editActions", align: "left" },
		],
		createRows,
	};
}
