import React, { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

const UserEditOptions = ({
	user,
	userListActions,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (action) => {
		action();
		handleMenuClose();
	};

	const actions = {
		remove: async () => userListActions.remove(user),
	}

	return (
		<div>
			<IconButton
				aria-controls="action-menu"
				aria-haspopup="true"
				onClick={handleMenuOpen}
				color="inherit"
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="action-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={() => handleMenuItemClick(actions.remove)}>Remove</MenuItem>
			</Menu>
		</div>
	);
};

export default UserEditOptions;
