import React from 'react';
import { Switch } from '@mui/material';

import { articleStatuses } from './constants';

const SwitchButton = ({
	item,
	listActions,
}) => {
	const enabled = item.status === articleStatuses.enabled;
	const handleChange = async () => {
		item.status = nextStatus(item.status);
		await listActions.update(item);
	};

	const nextStatus = () => {
		return item.status === articleStatuses.enabled
			? articleStatuses.disabled
			: articleStatuses.enabled;
	}

	return (
		<Switch
			checked={enabled}
			onChange={handleChange}
			color="primary"
		/>
	);
};

export default SwitchButton;
