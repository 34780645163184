import React, { useState } from 'react';

import {
	IconButton, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MDButton from 'components/MDButton';
import { request } from 'services/request';


import ProviderImageField from '../../../components/provider-edit-fields/provider-image-field';
import ProviderRatingField from '../../../components/provider-edit-fields/provider-rating-field';
import ProviderTextField from '../../../components/provider-edit-fields/provider-text-field';
import ProviderArrayField from '../../../components/provider-edit-fields/provider-array-field';
import ProviderArraySelectEnumField from '../../../components/provider-edit-fields/provider-array-enum-field';
import {
	categories, countryCodesList, investmentManagementTypes, investmentTypes
} from '../../constants';


export default function EditInvestProviderPopup({
	isPopupOpen,
	popUpActions,
	selectedProvider,
	providerListActions,
	showNotification,
}) {
	const [providerDraft, setProviderDraft] = useState(selectedProvider);
	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setProviderDraft((prevState) => ({
			...prevState,
			[name]: value,
		}))
	};

	const [selectedImage, setSelectedImage] = useState(null);
	const handleSaveChanges = () => {
		Promise.resolve(providerDraft)
			.then(() => storeImage(providerDraft, selectedImage))
			.then(providerListActions.update)
			.then(popUpActions.close);
	};

	const storeImage = async (providerDraft, selectedImage) => {
		if(!selectedImage)
			return providerDraft;

		const formData = new FormData();
		formData.append('file', selectedImage);

		return request.post({
			path: 'image/upload',
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then(res => {
				providerDraft.image = res.data.image;

				return providerDraft;
			})
			.catch(error => {
				console.error('File upload error:', error);
				showNotification('File uploading error.');
			});
	}

	return providerDraft && (
		<Dialog open={isPopupOpen} onClose={popUpActions.close} PaperProps={{ style: { width: 550 } }}>
			<IconButton
				edge="end"
				color="inherit"
				onClick={popUpActions.close}
				aria-label="close"
				sx={{ position: 'absolute', top: 10, right: 20 }}
			><CloseIcon/>
			</IconButton>
			<DialogTitle>Edit Investment provider</DialogTitle>
			<DialogContent>
				<div>
					{ProviderImageField({
						onChange: handleInputChange,
						selectedImage,
						setSelectedImage,
					})}
					{ProviderTextField({
						name: 'name',
						value: providerDraft.name || '',
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'description',
						value: providerDraft.description || '',
						onChange: handleInputChange,
					})}
					{ProviderRatingField({
						name: 'rating',
						value: providerDraft.rating || 0,
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'summary',
						value: providerDraft.summary || '',
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'introduction',
						value: providerDraft.introduction || '',
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'link',
						value: providerDraft.link || '',
						onChange: handleInputChange,
					})}
					{ProviderArraySelectEnumField({
						name: 'categories',
						value: providerDraft.categories || [],
						selectOptions: categories,
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'minDeposit',
						value: providerDraft.minDeposit || 0,
						onChange: handleInputChange,
					})}
					{ProviderArraySelectEnumField({
						name: 'investmentManagementType',
						value: providerDraft.investmentManagementType || [],
						selectOptions: investmentManagementTypes,
						onChange: handleInputChange,
					})}
					{ProviderArraySelectEnumField({
						name: 'supportedCountriesOfResidence',
						value: providerDraft.supportedCountriesOfResidence || [],
						selectOptions: countryCodesList,
						onChange: handleInputChange,
					})}
					{ProviderArraySelectEnumField({
						name: 'investmentType',
						value: providerDraft.investmentType || [],
						selectOptions: investmentTypes,
						onChange: handleInputChange,
					})}
					{ProviderArrayField({
						name: 'supportedStocks',
						value: providerDraft.supportedStocks || [],
						onChange: handleInputChange,
					})}
					{ProviderArrayField({
						name: 'supportedEtfs',
						value: providerDraft.supportedEtfs || [],
						onChange: handleInputChange,
					})}
				</div>

			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" size="small" onClick={handleSaveChanges}>
					Save Changes
				</MDButton>
			</DialogActions>
		</Dialog>
	)
}