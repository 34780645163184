import React from 'react';
import { TextField } from '@mui/material';

function ProviderLabelTextField ({
	name,
	value,
	onChange,
	placeholder = '',
	onClick,
}) {
	return (
		<TextField
			id={name}
			name={name}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			onClick={onClick}
			fullWidth
		/>
	)
}

export default ProviderLabelTextField;