import React, { useState } from 'react';

import {
	IconButton, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MDButton from 'components/MDButton';

import ProviderTextField from './popup-fields/user-text-field';
import SelectField from './popup-fields/select-field';
import { userRoles } from '../constants';

export default function EditUserPopup({
	isPopupOpen,
	popUpActions,
	selectedUser,
	userListActions,
}) {
	const [userDraft, setUserDraft] = useState(selectedUser);
	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setUserDraft((prevState) => ({
			...prevState,
			[name]: value,
		}))
	};

	const handleSaveChanges = () => {
		Promise.resolve(userDraft)
			.then(userListActions.update)
			.then(popUpActions.close);
	};

	return userDraft && (
		<Dialog open={isPopupOpen} onClose={popUpActions.close} PaperProps={{ style: { width: 550 } }}>
			<IconButton
				edge="end"
				color="inherit"
				onClick={popUpActions.close}
				aria-label="close"
				sx={{ position: 'absolute', top: 10, right: 20 }}
			><CloseIcon/>
			</IconButton>
			<DialogTitle>Edit User</DialogTitle>
			<DialogContent>
				<div>
					{ProviderTextField({
						name: 'name',
						value: userDraft.name || '',
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'email',
						value: userDraft.email || '',
						onChange: handleInputChange,
					})}
					{SelectField({
						name: 'role',
						value: userDraft.role,
						onChange: handleInputChange,
						selectOptions: userRoles,
					})}
					{ProviderTextField({
						name: 'password',
						value: userDraft.password || '',
						onChange: handleInputChange,
					})}
				</div>

			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" size="small" onClick={handleSaveChanges}>
					Save Changes
				</MDButton>
			</DialogActions>
		</Dialog>
	)
}