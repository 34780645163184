import React, { useState } from 'react';

import {
	IconButton, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MDButton from 'components/MDButton';

import ProviderTextField from './popup-fields/article-text-field';
import RichTextField from './popup-fields/rich-editor';
import ProviderArraySelectEnumField from '../../../components/provider-edit-fields/provider-array-enum-field';
import { articleCategories } from '../constants';

export default function EditPopup({
	isPopupOpen,
	popUpActions,
	selectedArticle,
	listActions,
	showNotification,
}) {
	const [draft, setDraft] = useState(selectedArticle);
	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setDraft((prevState) => ({
			...prevState,
			[name]: value,
		}))
	};

	const handleSaveChanges = () => {
		Promise.resolve(draft)
			.then(listActions.update)
			.then(popUpActions.close);
	};

	return draft && (
		<Dialog
			open={isPopupOpen}
			onClose={popUpActions.close}
			PaperProps={{ style: {
				width: 1000,
				maxWidth: 1000,
			} }}
		>
			<IconButton
				edge="end"
				color="inherit"
				onClick={popUpActions.close}
				aria-label="close"
				sx={{ position: 'absolute', top: 10, right: 20 }}
			><CloseIcon/>
			</IconButton>
			<DialogTitle>Edit Article</DialogTitle>
			<DialogContent>
				<div>
					{ProviderTextField({
						name: 'name',
						value: draft.name || '',
						onChange: handleInputChange,
					})}
					{ProviderTextField({
						name: 'description',
						value: draft.description || '',
						onChange: handleInputChange,
					})}
					{ProviderArraySelectEnumField({
						name: 'categories',
						value: draft.categories || [],
						selectOptions: articleCategories,
						onChange: handleInputChange,
					})}
					{RichTextField({
						name: 'data',
						value: draft.data || '',
						onChange: handleInputChange,
						showNotification,
					})}

				</div>

			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" size="small" onClick={handleSaveChanges}>
					Save Changes
				</MDButton>
			</DialogActions>
		</Dialog>
	)
}