import { TextField } from '@mui/material';
import React from 'react';

function ArticleTextField ({
	name,
	value = '',
	onChange,
}) {
	return (
		<TextField
			sx={{ marginTop: 2 }}
			id={name}
			label={name}
			name={name}
			value={value}
			onChange={onChange}
			fullWidth
			minRows={1}
			maxRows={5}
			multiline
		/>
	)
}

export default ArticleTextField;