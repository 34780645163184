const articleCategories = {
    gettingStarted: 'Getting Started',
    options: 'Options',
    future: 'Future',
}
const articleStatuses = {
    enabled: 'enabled',
    disabled: 'disabled',
}

module.exports = {
    articleCategories,
    articleStatuses,
}
