import MDBox from 'components/MDBox';
import { Chip, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useState } from 'react';
import { camelCaseToTitleCase } from '../../utils/case.helper';

function ProviderArrayField ({
	name,
	value,
	onChange,
}) {
	const [buffer, setBuffer] = useState('');
	const label = camelCaseToTitleCase(name);
	const handleBufferChange = (event) => {
		const { value } = event.target;
		setBuffer(value)
	};
	const handleAddChip = (fieldValue) => {
		if(!fieldValue)return;
		if (!value.some(item => item === fieldValue)) {
			value.push(fieldValue);
			onChange({ target: { name, value } });
		}
		handleBufferChange({ target: { name, value: '' }});
	}
	const removeChip = (selected) => {
		value = value.filter((chip) => chip !== selected);
		onChange({ target: { name, value } });
	}
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleAddChip(buffer);
		}
	};

	return (
		<MDBox pt={1} pb={1}>
			<TextField
				sx={{ height: '40px' }}
				name={name}
				label={label}
				rows={1}
				value={buffer}
				fullWidth
				onChange={handleBufferChange}
				onKeyDown={handleKeyDown}
				InputProps={{
					endAdornment: (
						<IconButton
							onClick={() => handleAddChip(buffer)}>
							<AddCircleIcon/>
						</IconButton>
					),
				}}
			/>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{value.map((item, index) => (
					<Chip key={index} label={item} style={{ margin: '2px' }}
						  onDelete={() => removeChip(item)}
					/>
				))}
			</div>
		</MDBox>
	)
}

export default ProviderArrayField;
