import React, { useState } from 'react';

import {
	IconButton,
	Dialog, DialogContent, DialogTitle, DialogActions, Grid, Card,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DataTable from 'examples/Tables/DataTable';
import labelsTableData from 'components/provider-label-fields/labels-table';
import { defaultEducationalLabelsStub } from './default-educational-labels';

export default function EditLabelsPopup({
	isPopupOpen,
	popUpActions,
	selectedProvider,
	providerListActions,
}) {
	const originalLabels = selectedProvider?.labels && JSON.parse(selectedProvider.labels).data;
	const [labelsDraft, setLabelsDraft] = useState(originalLabels || []);
	const labelActions = {
		add: () => {
			setLabelsDraft((prevState) => {
				return [...prevState, {
					name: '',
					value: '',
				}];
			})
		},
		remove: (index) => {
			setLabelsDraft((prevState) => {
				prevState.splice(index, 1);

				return [...prevState];
			})
		},
		update: (event) => {
			const { name, value } = event.target;
			const index = name.split('-')[0];
			const field = name.split('-')[1];

			setLabelsDraft((prevState) => {
				prevState[index][field] = value;

				return [...prevState];
			})
		}
	}

	const calculateProviderRating = (labels) => {
		const ratings = labels.reduce((accum, next) => accum + Number(next.rating), 0);
		return (ratings / labels.length).toFixed(1);
	}

	const handleSaveChanges = () => {
		const updatedProvider = {
			...selectedProvider,
			rating: calculateProviderRating(labelsDraft),
			labels: JSON.stringify({
				data: labelsDraft,
			})
		};

		return Promise.resolve(updatedProvider)
			.then(providerListActions.update)
			.then(popUpActions.close);
	};

	const { columns, createRows } = labelsTableData({
		labelActions,
	});
	const tableData = createRows(labelsDraft);
	const resetLabelsToDraft = () => setLabelsDraft(defaultEducationalLabelsStub);

	return labelsDraft && (
		<Dialog open={isPopupOpen} onClose={popUpActions.close}
				PaperProps={{ style: { maxWidth: 1250 } }}>
			<IconButton
				edge="end"
				color="inherit"
				onClick={popUpActions.close}
				aria-label="close"
				sx={{ position: 'absolute', top: 10, right: 20 }}
			>
				<CloseIcon/>
			</IconButton>
			<DialogTitle>Edit provider labels</DialogTitle>
			<MDButton>Reset to defaults</MDButton>
			<DialogContent>
				<MDButton
					onClick={resetLabelsToDraft}
					variant="outlined" color="info" size="small">
					Reset to defaults
				</MDButton>
				<MDBox pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox pt={3}>
									<DataTable
										table={{
											columns,
											rows: tableData,
										}}
										isSorted={false}
										entriesPerPage={false}
										showTotalEntries={false}
										noEndBorder
									/>
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>

			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" size="small"
						  onClick={handleSaveChanges}
				>
					Save Changes
				</MDButton>
			</DialogActions>
		</Dialog>
	)
}