import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Snackbar, Grid, Card } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import { request } from 'services/request';

import educationalProvidersTableData from './data/educational-providers-table-data';
import EditEducProviderPopup from './popups/edit-provider-popup';
import EditLabelsPopup from './popups/edit-provider-labels';
import { providerType } from '../constants';
import { defaultEducationalLabelsStub } from './popups/default-educational-labels';

const useStyles = makeStyles(() => ({
	pointer: {
		cursor: 'pointer',
	},
}));

function EducationalProviders() {
	// provider
	const [providerData, setProviderData] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState(null);
	useEffect(() => {
		fetchData().then(providerListActions.set)
	}, []);

	const fetchData = async () => {
		try {
			const type = providerType.financialEducationProvider;
			const response = await request.get({
				path: `provider/all?type=${type}`,
			});

			return response.data;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const providerListActions = {
		set: (providers) => updateProviderList(
			providers,
			async data => data,
		),
		add: (providerToAdd) => updateProviderList(
			providerToAdd,
			async (provider) => providerData.concat(provider)
		),
		remove: (providerToRemove) => updateProviderList(
			providerToRemove,
			async (providerToRemove) => {
				await request.delete({
					path: `provider/delete/${providerToRemove.id}`,
				});
				showNotification('Provider removed successfully.')

				return providerData.filter(provider => provider.id !== providerToRemove.id)
			}
		),
		update: (providerToUpdate) => updateProviderList(
			providerToUpdate,
			async (providerToUpdate) => {
				await updateProvider(providerToUpdate);
				showNotification('Provider updated successfully.');

				return providerData.map(provider => {
					return provider.id === providerToUpdate.id
						? providerToUpdate
						: provider;
				})
			}
		),
		addDraft: () => updateProviderList(
			createDraftProvider(),
			async (providerDraft) => {
				const newProvider = await request.post({
					path: `provider`,
					data: providerDraft
				})

				showNotification('Provider created successfully.')

				return providerData.concat(newProvider.data);
			}
		),
	}
	const updateProviderList = async (provider, actionFn) => {
		setProviderData(await actionFn(provider));
	}
	const createDraftProvider = () => {
		return {
			type: providerType.financialEducationProvider,
			fields: {
				name: 'Provider Draft',
				link: '',
				labels: JSON.stringify({
					data: defaultEducationalLabelsStub
				})
			}
		}
	}
	const updateProvider = async (providerDraft) => {
		const response = await request.put({
			path: `provider/update/${providerDraft.id}`,
			data: {
				...providerDraft
			}
		})

		return response.data;
	}

	// edit provider popup
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const popupActions = {
		open: (provider) => {
			setSelectedProvider(provider)
			setIsPopupOpen(true);
		},
		close: () => {
			setIsPopupOpen(false);
			setSelectedProvider(null);
		},
	}

	// edit provider labels popup
	const [isLabelsPopupOpen, setIsLabelsPopupOpen] = useState(false);
	const labelPopupActions = {
		open: (provider) => {
			setSelectedProvider(provider)
			setIsLabelsPopupOpen(true);
		},
		close: () => {
			setSelectedProvider(null);
			setIsLabelsPopupOpen(false);
		},
	}

	// notification
	const [showSnackbar, setShowSnackbar] = useState(false);
	const defaultSnackbarText = 'Success.'
	const [snackbarText, setSnackbarText] = useState(defaultSnackbarText);
	const showNotification = (text) => {
		setSnackbarText(text || defaultSnackbarText);
		setShowSnackbar(true);
	};
	const handleSnackbarClose = () => {
		setShowSnackbar(false);
		setSnackbarText(defaultSnackbarText);
	};

	const classes = useStyles();
	// table data
	const { columns, createRows } = educationalProvidersTableData({
		classes,
		popupActions,
		labelPopupActions,
		providerListActions,
	});

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant='gradient'
								bgColor='info'
								borderRadius='lg'
								coloredShadow='info'
							>
								<MDTypography variant='h6' color='white'>
									Educational providers management
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								{providerData ? (
									<DataTable
										table={{
											columns,
											rows: createRows(providerData),
										}}
										entriesPerPage={{ defaultValue: 9999 }}
										showTotalEntries={false}
										isSorted={true}
										noEndBorder
									/>
								) : (
									<p>Receiving data...</p>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				message={snackbarText}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			/>
			{selectedProvider && <EditEducProviderPopup
				isPopupOpen={isPopupOpen}
				popUpActions={popupActions}
				showNotification={showNotification}
				selectedProvider={selectedProvider}
				providerListActions={providerListActions}
			/>}
			{selectedProvider && <EditLabelsPopup
				isPopupOpen={isLabelsPopupOpen}
				popUpActions={labelPopupActions}
				showNotification={showNotification}
				selectedProvider={selectedProvider}
				providerListActions={providerListActions}
			/>}
		</DashboardLayout>
	);
}

export default EducationalProviders;
