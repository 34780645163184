import React from 'react';

import MDTypography from "components/MDTypography";
import ProviderLabelTextField from './label-text-field';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import ProviderRatingField from '../provider-edit-fields/provider-rating-field';

export default function data({
	labelActions,
}) {
	const createRows = (data) => {
		return [
			...transformDataToRows(data),
			// createNewLabelRow(),
		]
	}

	// const createNewLabelRow = () => ({
	// 	name: (
	// 		<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
	// 			{ProviderLabelTextField({
	// 				name: `create-new`,
	// 				value: '',
	// 				onClick: labelActions.add,
	// 				placeholder: 'Create new label'
	// 			})}
	// 		</MDTypography>
	// 	),
	// })

	const transformDataToRows = (data) => {
		return data.map((label, index) => ({
			name: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					{ProviderLabelTextField({
						name: `${index}-name`,
						value: label.name,
						onChange: labelActions.update,
					})}
				</MDTypography>
			),
			value: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					{ProviderLabelTextField({
						name: `${index}-value`,
						value: label.value,
						onChange: labelActions.update,
					})}
				</MDTypography>
			),
			description: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					{ProviderLabelTextField({
						name: `${index}-description`,
						value: label.description || '',
						onChange: labelActions.update,
					})}
				</MDTypography>
			),
			rating: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					{ProviderRatingField({
						name: `${index}-rating`,
						value: label.rating || 0,
						showLegend: false,
						onChange: labelActions.update,
					})}
				</MDTypography>
			),
			remove: (
				<IconButton
					edge="end"
					color="inherit"
					onClick={() => labelActions.remove(index)}
					aria-label="close"
				><CloseIcon/>
				</IconButton>
			),
		}))
	}

	return {
		columns: [
			{ Header: "Name", accessor: "name", width: "45%", align: "left" },
			{ Header: "Value", accessor: "value", align: "left" },
			{ Header: "Rating", accessor: "rating", align: "left" },
			{ Header: "Description", accessor: "description", align: "left" },
			// { Header: "Remove", accessor: "remove", align: "left" },
		],
		createRows,
	};
}
