import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { camelCaseToTitleCase } from '../../utils/case.helper';

function ProviderArraySelectEnumField({
	name,
	value,
	selectOptions,
	onChange,
}) {
	const removeChip = (selected) => {
		value = value.filter((chip) => chip !== selected);
		onChange({ target: { name, value } })
	}
	const label = camelCaseToTitleCase(name);
	const reversedSelectOptions = {};
	Object.keys(selectOptions)
		.forEach(key => reversedSelectOptions[selectOptions[key]] = key)

	return (
		<FormControl sx={{ marginTop: 2 }} fullWidth>
			<InputLabel id="select-label">{label}</InputLabel>
			<Select
				sx={{ height: '40px' }}
				name={name}
				label={label}
				value={value}
				onChange={onChange}
				fullWidth
				multiple>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{value.map((item, index) => (
						<Chip key={index} label={item} style={{ margin: '2px' }}
							  onDelete={() => removeChip(item)}
						/>
					))}
				</div>
				{Object.keys(reversedSelectOptions).sort().map((key, index) => (
					<MenuItem key={index} value={reversedSelectOptions[key]}>
						{key}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default ProviderArraySelectEnumField;