import { v1 } from 'uuid';
import React, { useState, useEffect } from 'react';

import { Snackbar, Grid, Card } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import { request } from 'services/request';

import usersTableData from './data/users-table-data';
import EditUserPopup from './popups/edit-user-popup';
import { userRoles } from './constants';

function EditUsersList() {
	// user
	const [usersData, setUsersData] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	useEffect(() => {
		fetchData().then(userListActions.set)
	}, []);

	const fetchData = async () => {
		try {
			const response = await request.get({
				path: 'user/all',
			});

			return response.data;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const userListActions = {
		set: (users) => updateUserList(
			users,
			async data => data,
		),
		add: (userToAdd) => updateUserList(
			userToAdd,
			async (user) => usersData.concat(user)
		),
		remove: (userToRemove) => updateUserList(
			userToRemove,
			async (userToRemove) => {
				await request.delete({
					path: `user/delete/${userToRemove.id}`,
				})
				showNotification('User removed successfully.')

				return usersData.filter(user => user.id !== userToRemove.id)
			}
		),
		update: (userToUpdate) => updateUserList(
			userToUpdate,
			async (userToUpdate) => {
				await updateUser(userToUpdate);
				showNotification('User updated successfully.');

				const { password, ...newUserData} = userToUpdate;

				return usersData.map(user => {
					return user.id === userToUpdate.id
						? newUserData
						: user;
				})
			}
		),
		addDraft: () => updateUserList(
			createDraftUser(),
			async (userDraft) => {
				const newUser = await request.post({
					path: `user/create`,
					data: userDraft,
				})

				showNotification('User created successfully.')

				return usersData.concat(newUser.data);
			}
		),
	}
	const updateUserList = async (users, actionFn) => {
		setUsersData(await actionFn(users));
	}
	const createDraftUser = () => {
		return {
			name: 'User Draft',
			email: `${v1()}@demo.com`,
			password: 'test_password',
			role: userRoles.ADMIN,
		}
	}
	const updateUser = async (userDraft) => {
		const response = await request.put({
			path: `user/update/${userDraft.id}`,
			data: {
				...userDraft
			}
		})

		return response.data;
	}

	// edit user popup
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const popupActions = {
		open: (user) => {
			setSelectedUser(user)
			setIsPopupOpen(true);
		},
		close: () => {
			setIsPopupOpen(false);
			setSelectedUser(null);
		},
	}

	// notification
	const [showSnackbar, setShowSnackbar] = useState(false);
	const defaultSnackbarText = 'Success.'
	const [snackbarText, setSnackbarText] = useState(defaultSnackbarText);
	const showNotification = (text) => {
		setSnackbarText(text || defaultSnackbarText);
		setShowSnackbar(true);
	};
	const handleSnackbarClose = () => {
		setShowSnackbar(false);
		setSnackbarText(defaultSnackbarText);
	};

	// table data
	const { columns, createRows } = usersTableData({
		popupActions,
		userListActions,
		showNotification
	});

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox mx={2} mt={-3} py={3} px={2}
								variant='gradient' bgColor='info' borderRadius='lg' coloredShadow='info'>
								<MDTypography variant='h6' color='white'>
									Users management
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								{usersData ? (
									<DataTable
										table={{
											columns,
											rows: createRows(usersData),
										}}
										entriesPerPage={{ defaultValue: 9999 }}
										showTotalEntries={false}
										isSorted={true}
										noEndBorder
									/>
								) : (
									<p>Receiving data...</p>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				message={snackbarText}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			/>
			{selectedUser && <EditUserPopup
				isPopupOpen={isPopupOpen}
				popUpActions={popupActions}
				selectedUser={selectedUser}
				userListActions={userListActions}
				showNotification={showNotification}
			/>}
		</DashboardLayout>
	);
}

export default EditUsersList;
