import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import MDBox from 'components/MDBox';
import config from 'config';
import { request } from '../../../../services/request';

function RichTextField({
	name,
	value,
	onChange,
	showNotification,
}) {
	const defaultInitValue = '<p>This is the initial content of the editor.</p>';
	const editorRef = useRef(null);

	const handleImageUpload = (blobInfo, success) => {
		const formData = new FormData();
		formData.append('file', blobInfo.blob(), blobInfo.filename());

		return request.post({
			path: 'article/media',
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then(res => {
				showNotification('File uploaded successfully.');
				success(res.data.media);
				return res.data.media;
			})
			.catch(error => {
				console.error('File upload error:', error);
				showNotification('File uploading error.');
			});
	};

	const handleVideoUpload = (blobInfo, success, failure) => {
		const formData = new FormData();
		formData.append('file', blobInfo.blob(), blobInfo.filename());

		// disabled in demo rich editor,
		// TODO the same as pictures
	};

	const onEdit = () => {
		if (editorRef.current) {
			onChange({
				target: {
					name,
					value: editorRef.current.getContent()
				}
			});
		}
	}

	return (
		<MDBox pt={1} pb={1}>
			<Editor
				apiKey={config.tinymceApiKey}
				// tinymceScriptSrc={config.publicUrl + '/tinymce/tinymce.min.js'} // for self-hosted script
				onInit={(evt, editor) => editorRef.current = editor}
				onMouseLeave={onEdit}
				initialValue={value || defaultInitValue}
				init={{
					images_upload_handler: handleImageUpload,
					media_upload_handler: handleVideoUpload,
					file_picker_types: 'image media',
					// file_picker_callback: (callback, value, meta) => {
					// 	if (meta.filetype === 'image') {
					// 		// Открываем диалоговое окно выбора изображения
					// 	} else if (meta.filetype === 'video') {
					// 		// Открываем диалоговое окно выбора видео
					// 	}
					// },
				}}
			/>
		</MDBox>
	)
}

export default RichTextField;
