import React, { useState, useEffect } from 'react';

import { Snackbar, Grid, Card } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import { request } from 'services/request';

import articlesTableData from './data/articles-table-data';
import EditPopup from './popups/edit-popup';

function EditArticlesList() {
	const tableTitle = 'Articles management';
	const [articlesData, setArticlesData] = useState([]);
	const [selectedArticle, setSelectedArticle] = useState(null);
	useEffect(() => {
		fetchData().then(listActions.set)
	}, []);

	const fetchData = async () => {
		try {
			const response = await request.get({
				path: 'article/all',
			});

			return response.data;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const listActions = {
		set: (items) => updateList(
			items,
			async data => data,
		),
		add: (userToAdd) => updateList(
			userToAdd,
			async (item) => articlesData.concat(item)
		),
		remove: (itemToRemove) => updateList(
			itemToRemove,
			async (itemToRemove) => {
				await request.delete({
					path: `article/delete/${itemToRemove.id}`,
				})
				showNotification('Article removed successfully.')

				return articlesData.filter(item => item.id !== itemToRemove.id)
			}
		),
		update: (item) => updateList(
			item,
			async (itemToUpdate) => {
				await updateItem(itemToUpdate);
				showNotification('Article updated successfully.');

				return articlesData.map(item => {
					return item.id === itemToUpdate.id
						? itemToUpdate
						: item;
				})
			}
		),
		addDraft: () => updateList(
			createDraftArticle(),
			async (draft) => {
				const newItem = await request.post({
					path: `article/create`,
					data: draft,
				})

				showNotification('Article created successfully.')

				return articlesData.concat(newItem.data);
			}
		),
	}
	const updateList = async (items, actionFn) => {
		setArticlesData(await actionFn(items));
	}
	const createDraftArticle = () => {
		return {
			name: 'Article Draft',
			description: 'Article test description',
			categories: [],
			data: ''
		}
	}
	const updateItem = async (draft) => {
		const response = await request.put({
			path: `article/update/${draft.id}`,
			data: draft
		})

		return response.data;
	}

	// edit popup
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const popupActions = {
		open: (item) => {
			setSelectedArticle(item)
			setIsPopupOpen(true);
		},
		close: () => {
			setIsPopupOpen(false);
			setSelectedArticle(null);
		},
	}

	// notification
	const [showSnackbar, setShowSnackbar] = useState(false);
	const defaultSnackbarText = 'Success.'
	const [snackbarText, setSnackbarText] = useState(defaultSnackbarText);
	const showNotification = (text) => {
		setSnackbarText(text || defaultSnackbarText);
		setShowSnackbar(true);
	};
	const handleSnackbarClose = () => {
		setShowSnackbar(false);
		setSnackbarText(defaultSnackbarText);
	};

	// table data
	const { columns, createRows } = articlesTableData({
		popupActions,
		listActions,
		showNotification
	});

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant='gradient'
								bgColor='info'
								borderRadius='lg'
								coloredShadow='info'
							>
								<MDTypography variant='h6' color='white'>
									{tableTitle}
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								{articlesData ? (
									<DataTable
										table={{
											columns,
											rows: createRows(articlesData),
										}}
										entriesPerPage={{ defaultValue: 9999 }}
										showTotalEntries={false}
										isSorted={true}
										noEndBorder
									/>
								) : (
									<p>Receiving data...</p>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				message={snackbarText}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			/>
			{selectedArticle && <EditPopup
				isPopupOpen={isPopupOpen}
				popUpActions={popupActions}
				selectedArticle={selectedArticle}
				listActions={listActions}
				showNotification={showNotification}
			/>}
		</DashboardLayout>
	);
}

export default EditArticlesList;
