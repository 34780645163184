import React from 'react';
import { Rating, Typography } from '@mui/material';

import MDBox from 'components/MDBox';

function ProviderRatingField ({
	name,
	value = 0,
	showLegend = true,
	onChange,
}) {
	return (
		<MDBox>
			{showLegend && <Typography variant={'string'} component="legend">{name}</Typography>}
			<Rating
				name={name}
				value={Number(value)}
				precision={0.5}
				onChange={onChange}
			/>
		</MDBox>
	)
}

export default ProviderRatingField;