import * as XLSX from 'xlsx';

import { Card, Grid } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { request } from 'services/request';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

function ClientsPage() {
	const createXLXSClientsFile = async () => {
		const clientsData = await fetchData();
		const clientsDataMap = clientsData.map(flatObject);

		exportToExcel(clientsDataMap)
	}

	const flatObject = (obj) => {
		return {
			...obj,
			...obj.data,
		}
	}

	const fetchData = async () => {
		try {
			const response = await request.get({ path: 'client' });

			return response.data;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const exportToExcel = (clientsData) => {
		const workbook = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(clientsData);
		XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
		const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = 'data.xlsx';
		a.click();

		URL.revokeObjectURL(url);
	};

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox mx={2} mt={-3} py={3} px={2}
								   variant='gradient' bgColor='info' borderRadius='lg' coloredShadow='info'>
								<MDTypography variant='h6' color='white'>
									Clients management
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								<MDButton variant="outlined" color="info" size="small"
										  onClick={createXLXSClientsFile}>
									Get clients list
								</MDButton>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default ClientsPage;
