import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import MDButton from 'components/MDButton';

function ProviderImageField({
	selectedImage,
	setSelectedImage,
}) {
	const [imagePreview, setImagePreview] = useState(null);
	const onDrop = useCallback((acceptedFiles) => {
		setSelectedImage(acceptedFiles[0]);
	}, []);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	useEffect( () => {
		if (selectedImage && typeof selectedImage !== 'string') {
			createImagePreview(selectedImage);
		}
	}, [selectedImage]);

	const createImagePreview = (file) => {
		const reader = new FileReader();
		reader.onload = () => {
			setImagePreview(reader.result);
		};

		reader.readAsDataURL(file);
	}

	return (
		<div {...getRootProps()}>
			<Box display="flex" alignItems="center" gap={2}>
				<Box>
					{selectedImage ? (
						<Avatar src={imagePreview} alt="Preview" />
					) : (
						<Avatar />
					)}
				</Box>
				<Box>
					<input {...getInputProps()} />
					<MDButton
						variant="outlined"
						color="info" size="small"
					>
						Select image.
					</MDButton>
				</Box>
			</Box>
		</div>
	)
}

export default ProviderImageField;