import Icon from '@mui/material/Icon';

import ClientsPage from 'layouts/clients';
import EditUsersList from 'layouts/users';
import EditArticlesList from 'layouts/articles';
import InvestmentProviders from 'layouts/investment-providers';
import EducationalProviders from 'layouts/educational-providers';
import SignIn from 'layouts/authentication/components/SignIn';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React from 'react';

const routes = [
  {
    type: 'collapse',
    name: 'Investment providers',
    key: 'investment-providers',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/investment-providers',
    component: <InvestmentProviders />,
  },
  {
    type: 'collapse',
    name: 'Educational providers',
    key: 'educational-providers',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/educational-providers',
    component: <EducationalProviders />,
  },
  {
    type: "collapse",
    name: "Articles",
    key: "articles",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/articles",
    component: <EditArticlesList />,
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/users',
    component: <EditUsersList />,
  },
  {
    type: 'collapse',
    name: 'Clients',
    key: 'clients',
    icon: <Icon fontSize="small"><AttachMoneyIcon /></Icon>,
    route: '/clients',
    component: <ClientsPage />,
  },
  {
    type: 'collapse',
    name: 'Sign Out',
    key: 'sign-in',
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/authentication/sign-in',
    component: <SignIn />,
  },
];

export default routes;
