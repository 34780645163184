import React, { useState } from 'react';
import { Switch } from '@mui/material';

import { providerStatus } from '../constants';
import { request } from 'services/request';

const SwitchButton = ({
	item,
}) => {
	const enabled = item.status === providerStatus.enabled;
	const [checked, setChecked] = useState(enabled);

	const handleChange = () => {
		item.status = nextStatus(item.status);
		updateProviderStatus()
			.then(() => setChecked(!checked));
	};

	const nextStatus = () => {
		return item.status === providerStatus.enabled
			? providerStatus.disabled
			: providerStatus.enabled;
	}

	const updateProviderStatus = async () => {
		await request.put({
			path: `user/update/${item.id}`,
			data: {
				status: item.status,
			}
		})
	}

	return (
		<Switch
			checked={checked}
			onChange={handleChange}
			color="primary"
		/>
	);
};

export default SwitchButton;
