import React from 'react';

import Avatar from '@mui/material/Avatar';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import plusImage from "assets/images/plus.png";

import ProviderEditOptions from '../provider-edit-options';
import SwitchButton from '../provider-switch';

export default function data({
	classes,
	popupActions,
	labelPopupActions,
	providerListActions,
}) {
	const Provider = ({
		image, name, link, style,
		onClick
	}) => {
		const getImageUrl = (imageUrl) => {
			return imageUrl
				? imageUrl
				: '';
		}

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1} onClick={onClick}>
				<Avatar src={getImageUrl(image)} alt="Preview"/>
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium" sx={style}>
						{name}
					</MDTypography>
					<MDTypography variant="caption">{link}</MDTypography>
				</MDBox>
			</MDBox>
		)
	};

	const createRows = (data) => {
		return [
			...transformDataToRows(data),
			createProviderRow(),
		]
	}

	const transformDataToRows = (data) => {
		return data.map(provider => ({
			name: <Provider
				image={provider.image}
				name={provider.name}
				link={provider.link}
			/>,
			status: (
				<MDTypography component="a" variant="caption" color="text" fontWeight="medium">
					<SwitchButton
						provider={provider}
						providerListActions={providerListActions}
					/>
				</MDTypography>
			),
			mainInfo: (
				<MDTypography
					className={classes.pointer}
					component="a" variant="caption" color="text" fontWeight="medium"
					onClick={() => popupActions.open(provider)}>
					Edit
				</MDTypography>
			),
			compareData: (
				<MDTypography
					className={classes.pointer}
					component="a" variant="caption" color="text" fontWeight="medium"
					onClick={() => labelPopupActions.open(provider)}>
					Edit
				</MDTypography>
			),
			editActions: (
				<MDTypography
					component="a" variant="caption" color="text" fontWeight="medium">
					<ProviderEditOptions
						provider={provider}
						providerListActions={providerListActions}
					/>
				</MDTypography>
			),
		}))
	}

	const createProviderRow = () => ({
		name: <Provider
			style={{
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: 'lightgray',
				},
			}}
			name={'Create new provider...'}
			link={''}
			image={plusImage}
			onClick={providerListActions.addDraft}
		/>,
	})

	return {
		columns: [
			{ Header: "Provider", accessor: "name", width: "45%", align: "left" },
			{ Header: "Status", accessor: "status", align: "left" },
			{ Header: "Provider info", accessor: "mainInfo", align: "left" },
			{ Header: "Labels", accessor: "compareData", align: "left" },
			{ Header: "Actions", accessor: "editActions", align: "left" },
		],
		createRows,
	};
}
